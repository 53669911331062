<template>
    <div class="ui-content-box">
        <div v-if="$slots.header" class="ui-content-box__header">
            <slot name="header"></slot>
        </div>
        <div v-if="$slots.content" class="ui-content-box__content">
            <slot name="content"></slot>
        </div>
        <div v-if="$slots.footer" class="ui-content-box__footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>

export default {
    
    name: 'contentBox',

};

</script>

<style lang="scss" src="./contentBox.scss" />
