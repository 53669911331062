<template>
    <div class="func-visit-summary">
        <contentBox>
            <template #header>
                <h2>Twoja rezerwacja</h2>
            </template>
            <template #content>

                <div class="summary-row">
                    <div class="summary-row__icon">
                        <img src="~@/assets/icons/calendar-ico.svg" />
                    </div>
                    <div class="summary-row__content">
                        <h3>{{selectedTerm.newVisit.timeFrom}}</h3>
                        <p>{{selectedTerm.newVisit.date}}</p>
                    </div>
                </div>

                <div class="summary-row">
                    <div class="summary-row__icon">
                        <img src="~@/assets/icons/doc_blue.svg" />
                    </div>
                    <div class="summary-row__content">
                        <h3>{{selectedTerm.doctor.first_name}} {{selectedTerm.doctor.last_name}}</h3>
                        <p>{{specialty}}</p>
                    </div>
                </div>

                <div class="summary-row">
                    <div class="summary-row__icon">
                        <img src="~@/assets/icons/facility-ico.svg" />
                    </div>
                    <div class="summary-row__content">
                        <h3>{{selectedTerm.department.name}}</h3>
                        <p v-if="!selectedTerm.evisit">{{selectedTerm.department.address}}</p>
                        <p v-if="selectedTerm.evisit">E-wizyta</p>
                    </div>
                </div>

                <div v-if="selectedService" class="summary-row">
                    <div class="summary-row__icon">
                        <img src="~@/assets/icons/stethoscope-ico.svg" />
                    </div>
                    <div class="summary-row__content">
                        <h3>{{selectedService.name}}</h3>
                        <p>Usługi prywatne</p>
                    </div>
                </div>

                <div v-if="selectedService" class="summary-row">
                    <div class="summary-row__icon">
                        <img src="~@/assets/icons/wallet-ico.svg" />
                    </div>
                    <div class="summary-row__content">
                        <h3>{{loyaltyDiscount.active ? selectedService.discountedPrice : selectedService.price}} zł</h3>
                        <p v-if="loyaltyDiscount.active">
                            ({{loyaltyDiscount.discount * 100}}% zniżki z {{selectedService.price}})
                        </p>
                        <p>Koszt</p>
                    </div>
                </div>

            </template>
        </contentBox>
    </div>
</template>

<script>

import { mapState } from 'vuex';

import contentBox from '@/components/ui/contentBox/contentBox.vue';

export default {

    name: 'visitSummary',
    components: {
        contentBox,
    },
    computed: {
        ...mapState('global', [
            'selectedTerm',
            'selectedService',
            'visitType',
            'visitKind',
            'selectedSpeciality',
        ]),
        ...mapState('user', ['user', 'facility', 'loyaltyDiscount']),
        specialty() {
            return this.selectedSpeciality && this.selectedSpeciality.name ? this.selectedSpeciality.name
                : this.selectedTerm.newVisit.specialties.map(specialty => specialty.name).join(', ');

        },
    },

};

</script>

<style lang="scss" src="./visitSummary.scss" />
