<template>
    <div class="app-splited-view">
        <div v-if="$slots.left" class="app-splited-view__left">
            <slot name="left"></slot>
        </div>
        <div v-if="$slots.right" class="app-splited-view__right">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>

export default {
    
    name: 'splitedView',

};

</script>

<style lang="scss" src="./splitedView.scss" />
