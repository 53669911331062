<template>
    <div
    class="ui-price-label"
    :class="{'ui-price-label--active': active}"
    @click="$emit('click', $event)">
        <slot></slot>
    </div>
</template>

<script>

export default {
    
    name: 'priceLabel',
    props: {

        active: {
            type: Boolean,
            default: false,
        },

    },

};

</script>

<style lang="scss" src="./priceLabel.scss" />
